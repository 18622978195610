import React from "react";
import Spinner from "react-bootstrap/Spinner";
export default function Fallback() {
  return (
    <div
      style={{ height: "100vh" }}
      className="w-100 d-flex justify-content-center align-items-center"
    >
      <Spinner />
    </div>
  );
}
