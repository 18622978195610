export const getSiteAsset = (fileName) =>
  `${process.env.REACT_APP_PAGE_ASSETS}/images/${fileName}`;

export const getSiteJson = (fileName) =>
  `${process.env.REACT_APP_PAGE_ASSETS}/jsons/${fileName}`;

export const getProductLink = (link) =>
  `${process.env.REACT_APP_LOAN_PRODUCTS_CDN}${link}`;

export const getSwmcReferralLink = (url, mloID) =>
  url && mloID ? `${url}?rlo=${mloID}` : url;

export const getPhoneText = (mloPhone) => {
  const phoneCopy = mloPhone?.slice();
  const phoneParts = phoneCopy?.split(".");
  const phoneText = `(${phoneParts[0]}) ${phoneParts[1]}-${phoneParts[2]}`;
  return phoneText;
};
export const getJustNumbers = (mloPhone) => {
  const phoneCopy = mloPhone?.slice();
  const phoneParts = phoneCopy?.split(".");
  let tempPhone = "";
  phoneParts?.forEach((num) => (tempPhone += num));
  const justNumbers = tempPhone.slice();
  return justNumbers;
};
export const getMloPhoneLink = (mloPhone) => {
  const phoneText = getPhoneText(mloPhone);
  const justNumbers = getJustNumbers(mloPhone);
  if ((phoneText, justNumbers))
    return <a  aria-label="Click here to call me." href={`tel:1${justNumbers}`}>{`D: ${phoneText}`}</a>;
};


export function isMobileOrTablet() {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const isMobile = width <= 768 && height <= 1024; // Adjust the values as needed
  const isTablet = width > 768 && width <= 991; // Adjust the values as needed
  return isMobile || isTablet;
}

export const customSubdomains = {
  rs: "rachels",
  rachelsantos: "rachels",
  gurpreetpahwa: "davidpahwa",
};