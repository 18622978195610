import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/poppins";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { isMobileOrTablet, customSubdomains } from "./utils";
import rootReducer from "./store/reducers";
import { createStore } from "redux";
const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
const smallDevice = isMobileOrTablet();

// custom subdomains that mlo's are using.
//the subdomain does not reference their json file
// so must programically change their subdomain value in the background

//url parts
const urlParts = window.location.host.split(".");
//for testing only. gets session storage for subdomain and domain.
//not used for production
const sessionDomain = sessionStorage.getItem("domain");
const sessionSubdomain = sessionStorage.getItem("mlo");
//domain to search
const domain = sessionDomain ? sessionDomain : urlParts[1];

//uncomment the below for testing specific mlo site without testing selection page
// const urlParts = "adamalvanos.sunwestmortgage.com".split(".");
const sessionOrUrl = sessionSubdomain ? sessionSubdomain : urlParts[0];

//subdomain contains name of mlo to be displayed
const subdomain = customSubdomains[sessionOrUrl] || sessionOrUrl;

const root = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <Router
      basename={process.env.REACT_APP_BASE_NAME}
      future={{ v7_startTransition: true, v7_relativeSplatPath: true }}
    >
      <Provider store={store}>
        <App subdomain={subdomain} domain={domain} smallDevice={smallDevice} />
      </Provider>
    </Router>
  </React.StrictMode>,
  root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
